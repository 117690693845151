import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PropTypes from "prop-types";
import SEO from "../components/seo";

// Range Slider
export default function Hymn({ data }) {
  const title = data.hymnJson.title;
  const lyrics = data.hymnJson.lyrics;
  const author = data.hymnJson.author;
  return (
    <Layout>
      <SEO
        keywords={[
          `${title}`,
          `${title}詩歌`,
          `${title}輕音樂`,
          `${title} 輕音樂`,
          `${author}`,
          `讚美詩歌`,
        ]}
        title={`${title}`}
        description={`${title} - ${author}`}
      />
      <h1 className="text-center font-bold text-2xl m-2">{title}</h1>
      {lyrics.map((p, i) =>
        p.length ? (
          <p key={i} className="text-center my-1 text-xl">
            {p}
          </p>
        ) : (
          <br key={i} className="text-center my-1 text-xl"></br>
        )
      )}
    </Layout>
  );
}

Hymn.propTypes = {
  data: PropTypes.shape({
    hymnJson: PropTypes.shape({
      title: PropTypes.string,
      lyrics: PropTypes.arrayOf(PropTypes.string),
      author: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

export const query = graphql`
  query($ID: String!) {
    hymnJson(ID: { eq: $ID }) {
      title
      lyrics
      author
    }
  }
`;
